<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="fas fa-sitemap"></i> &nbsp;&nbsp;Agency List</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center" v-if="false">
                <p>You can add, edit or remove a downline client. When a new client is added the system will send an email to the client to complete payment,<br/>billing and reporting information. An email will also be sent with login information to their email.</p>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2" v-if="false">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div> -->
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-left pb-4" v-if="$global.systemUser && $global.menuUserType != 'sales' && $global.rootcomp">
                <i class="fas fa-building pr-2"></i>Select Company :
                <el-select
                    class="select-primary"
                    size="large"
                    placeholder="Select Company"
                    filterable
                    default-first-option
                    v-model="selects.rootSelected"
                    style="width:250px"
                    @change="viewRootCompany"
                >
                
                    <el-option
                        v-for="option in selects.rootList"
                        class="select-primary"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id"
                    >
                    </el-option>
                </el-select>
            </div>
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <card>

                    <div class="row" style="justify-content: space-between;">
                        <div class="container__input__agency__list">
                            <base-input>
                                <el-input type="search" class="search-input input__agency__list" clearable prefix-icon="el-icon-search"
                                    placeholder="Search" v-model="searchQuery" aria-controls="datatables" @keyup.native.enter="searchKeyWord" @clear="searchKeyWord">
                                </el-input>
                            </base-input>
                        </div>
                        <div class="container__filter__agency__list">
                            <base-button size="sm" class="button__filter__agency__list button_add__client__agency__list" style="height:40px" v-if="this.$global.settingMenuShow_create" @click="AddEditDownline('')">
                                <i class="fas fa-plus-circle"></i> Add Agency
                            </base-button>
                        </div>
                    </div>
                    
                    <ValidationObserver ref="frmAddDownline" id="showAddEditDownline" class="row collapse">
                         <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 

                         <div class="col-sm-6 col-md-6 col-lg-6 form-group has-label">
                            <ValidationProvider
                                name="Company Name"
                                rules="required"
                                v-slot="{ passed, failed, errors }"
                            >
                                <base-input
                                    label="Company Name*"
                                    type="text"
                                    placeholder="Input Company Name"
                                    addon-left-icon="fas fa-building"
                                    v-model="ClientCompanyName"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                    >
                                </base-input>
                            </ValidationProvider>
                        </div>
                         <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                                name="Full Name"
                                rules="required"
                                v-slot="{ passed, failed, errors }"
                            >
                                <base-input
                                    label="Full Name*"
                                    type="text"
                                    placeholder="Input Personal Name"
                                    addon-left-icon="far fa-id-badge"
                                    v-model="ClientFullName"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                    >
                                </base-input>
                            </ValidationProvider>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                                name="Email"
                                rules="required|email"
                                v-slot="{ passed, failed, errors }"
                            >
                                <base-input
                                    label="Email*"
                                    type="email"
                                    placeholder="Input Personal Email"
                                    addon-left-icon="fas fa-envelope"
                                    v-model="ClientEmail"
                                    :lowercase="true"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                    >
                                </base-input>
                            </ValidationProvider>
                          </div>

                           <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                                name="Phone Number"
                                rules="required"
                                v-slot="{ passed, failed, errors }"
                            >
                                <!-- <base-input
                                    label="Phone Number*"
                                    type="text"
                                    placeholder="Input Personal Phone"
                                    addon-left-icon="fas fa-phone-alt"
                                    v-model="ClientPhone"
                                    class="phonefield"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                    >
                                </base-input> -->
                                <label>Phone Number*</label>

                                <VuePhoneNumberInput :error="failed" :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" @update="onPhoneUpdate" />
                                
                            </ValidationProvider>
                          </div>
                          
                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label" v-if="false">
                            <base-input
                                label="Domain Name:"
                                type="text"
                                placeholder="yourdomain.com"
                                addon-left-icon="fas fa-globe-americas"
                                v-model="DownlineDomain"
                                >
                            </base-input>
                          </div>
                          <div class="col-sm-3 col-md-3 col-lg-3 form-group has-label">
                            <ValidationProvider
                                name="Subdomain"
                                :rules="{required:true,regex:/^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/}"
                                v-slot="{ passed, failed, errors }"
                            >
                                <base-input
                                    label="Subdomain*:"
                                    type="text"
                                    placeholder="yoursubdomain"
                                    addon-left-icon="fas fa-globe-americas"
                                    v-model="DownlineSubDomain"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                    >
                                </base-input>
                            </ValidationProvider>
                          </div>
                          <div class="col-sm-3 col-md-3 col-lg-3 ml-0 pl-0" style="padding:32px">
                            .{{rootDomain}}
                          </div>
                          <div class="col-6"></div>
                          <div class="col-sm-6 col-md-6 col-lg-6 form-group has-label">
                          <span class="" style="font-size: 0.80143rem;">Payment Term :</span>
                            <div class="row pl-3">
                                <base-checkbox
                                    v-for="term in rootpaymentterm"
                                    :key="term.value"
                                    class="mr-3"
                                    v-model="selectedterms[term.value]"
                                >
                                  {{ term.label }}
                                </base-checkbox>
                            </div>
                          </div>
                          <div class="col-6 form-group has-label">
                            <span class="" style="font-size: 0.80143rem;">White Label Your Domain :</span>
                            <div class="row pl-3">
                                <base-checkbox v-model="ClientWhiteLabeling">White Labelling</base-checkbox>
                            </div>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label" v-if="false">
                            <base-input
                                label="Organization ID :"
                                type="text"
                                placeholder="Input Organization ID"
                                addon-left-icon="fas fa-passport"
                                v-model="DownlineOrganizationID"
                                >
                            </base-input>
                          </div>

                          <div class="col-sm-8 col-md-8 col-lg-8 form-group has-label">
                            &nbsp;
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12">&nbsp;</div>
                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label">
                            <label class="pr-2"><i class="fas fa-user"></i> Sales Representative <a class="pl-2" href="#" v-on:click.prevent="removeSales('salesrep')"><i class="fas fa-times-circle fa-lg"></i></a></label>
                                <el-select
                                    class="select-primary"
                                    size="large"
                                    placeholder="Select Sales Representative"
                                    filterable
                                    default-first-option
                                    v-model="selects.salesRepSelected"
                                >
                                
                                    <el-option
                                        v-for="option in selects.salesList"
                                        class="select-primary"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="option.id"
                                    >
                                    </el-option>
                                </el-select>
                          </div>

                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label">
                            <label class="pr-2"><i class="fas fa-user-headset"></i> Account Executive <a class="pl-2" href="#" v-on:click.prevent="removeSales('salesae')"><i class="fas fa-times-circle fa-lg"></i></a></label>
                            <el-select
                                class="select-primary"
                                size="large"
                                placeholder="Select Account Executive"
                                filterable
                                default-first-option
                                v-model="selects.salesAESelected"
                            >
                            
                                <el-option
                                    v-for="option in selects.salesList"
                                    class="select-primary"
                                    :value="option.id"
                                    :label="option.name"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>
                          </div>
                          
                          <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label">
                            <label class="pr-2"><i class="fas fa-user-tag"></i> Referral Account <a class="pl-2" href="#" v-on:click.prevent="removeSales('salesref')"><i class="fas fa-times-circle fa-lg"></i></a></label>
                            <el-select
                                class="select-primary"
                                size="large"
                                placeholder="Select Referral Account"
                                filterable
                                default-first-option
                                v-model="selects.salesRefSelected"
                            >
                            
                                <el-option
                                    v-for="option in selects.salesList"
                                    class="select-primary"
                                    :value="option.id"
                                    :label="option.name"
                                    :key="option.id"
                                >
                                </el-option>
                            </el-select>

                          </div>
                          <div class="col-sm-3 col-md-3 col-lg-3 ml-3" hidden>
                                    <div class="form-group has-label pull-left">
                                        <label class="row">Two-factor Authentication</label>
                                        <div class="row">
                                        <base-checkbox 
                                        :checked="twoFactorAuth === 'email'" 
                                        @change="selectTfa('email')" style="padding-inline:4px;">Email</base-checkbox>

                                        <base-checkbox 
                                            :checked="twoFactorAuth === 'twilio'" 
                                            @change="selectTfa('twilio')" style="padding-inline:4px;">Twilio</base-checkbox>

                                        <base-checkbox 
                                            :checked="twoFactorAuth === 'whatsapp'" 
                                            @change="selectTfa('whatsapp')" style="padding-inline:4px;">WhatsApp</base-checkbox>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div>
                        
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <base-button size="sm" class="pull-right" id="btnSave" name="btnSave" style="height:40px" @click="ProcessAddEditDownline('','')">
                                Save
                              </base-button>
                              <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditDownline('')">
                                Cancel
                              </base-button>
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div> 
                     </ValidationObserver>
                    
                    <div class="row">
                         <div class="col-sm-12 col-md-12 col-lg-12">
                             <tree :rootDomain="rootDomain" :mypackages="mypackages" :tree-data="queriedData" :GetDownlineList="GetDownlineList" :sortOrder="sortOrder" @update-order-by="updateOrderBy" @refresh-list="getRootList();"></tree>
                         </div>
                    </div>
                    
                    <template slot="footer">
                        <div class="tab-footer pull-right">
                                    <div class="pt-3">
                                        <p class="card-category">
                                            Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                                        </p>
                                    </div>
                                        <base-pagination
                                        class="pagination-no-border pt-4"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total="pagination.total"
                                        @input="changePage"
                                        >
                                        </base-pagination>
                                </div>
                    </template>

                 </card>
             </div>    
        </div>
                            <!-- Modal Video Guide -->
                            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">What is on Dashboard Menu?</h4>
                              <p class="text-center">
                                Watch the video below if you still have question please <a href="#">Contact Us</a>
                              </p>
                              <div>
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok I already understand</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Video Guide -->

                            <!-- Modal Setting Markup -->
                            <modal :show.sync="modals.pricesetup" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">Set Markup For this Client:</h4>
                              <p class="text-center">
                                <a href="#">Click here</a> to watch video if you need more explanation.
                              </p>
                              <div style="display:none">
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <div style="height:20px">&nbsp;</div>
                              <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12">
                                        <label>Select Modules:</label>
                                        <el-select
                                            class="select-primary"
                                            size="large"
                                            placeholder="Select Modules"
                                            v-model="selectsAppModule.AppModuleSelect"
                                            style="padding-left:10px"
                                            >
                                            <el-option
                                                v-for="option in selectsAppModule.AppModule"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="option.label"
                                                :key="option.label"
                                            >
                                            </el-option>
                                        </el-select>
                                  </div>
                              </div>
                              <div style="height:20px">&nbsp;</div>

                               <div class="row" v-if="selectsAppModule.AppModuleSelect == 'Ads Design'">
                                   <card>
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            Currently Cost Per Design is <strong>$10</strong>
                                        </div>
                                        <div class="row pt-3">
                                            <div class="col-sm-5 col-md-5 col-lg-5" style="max-width:36%">
                                                <label>Enter Markup Number:</label>
                                                    <el-select
                                                        class="select-primary"
                                                        size="small"
                                                        placeholder="Select Type"
                                                        v-model="selectsPTAds.PTAdsSelect"
                                                        style="padding-left:10px"
                                                        >
                                                        <el-option
                                                            v-for="option in selectsPTAds.PTAds"
                                                            class="select-primary"
                                                            :value="option.value"
                                                            :label="option.label"
                                                            :key="option.label"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                            </div>

                                            <div class="col-sm-3 col-md-3 col-lg-3">
                                                    <base-input
                                                        label=""
                                                        type="text"
                                                        placeholder="Input Amount"
                                                        addon-left-icon="fas fa-dollar-sign"                    
                                                    >
                                                    </base-input>
                                            </div>

                                            <div class="col-sm-3 col-md-3 col-lg-3" style="line-height:40px">
                                                 <strong> = $<span>25</span></strong>
                                            </div>

                                        </div>     
                                       
                                   </card>
                                    
                               </div>

                              <div class="row">
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok, Set It Up!</base-button>
                                </div>
                              </template>
                            </modal>
                           <!-- Modal Setting Markup -->

    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Collapse, CollapseItem,Modal,Tree } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";
import VuePhoneNumberInput from '@/components/VuePhoneNumberInput';
extend("email", email);
extend("required", required);
extend("regex", regex);

export default {
    components: {
        Modal,
        Collapse,
        CollapseItem,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        BasePagination,
        Tree,
        VuePhoneNumberInput
       
    },
    data() {
        return {
            clientPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            },
            currentPage:2,
            lastPage:2,
             tableDataOri:[],
             tableData: [],
             fuseSearch: null,
             searchedData: [],
             searchQuery: '',
             pagination: {
                perPage: 10,
                currentPage: 1,
                // perPageOptions: [],
                total: 0,
                from: 0,
                to: 0,
            },
            modals: {
                helpguide: false,
                pricesetup: false,
            },
            companyID:'',
            salesID: '',
            ClientWhiteLabeling: true,
            ClientCompanyName: '',
            ClientFullName: '',
            ClientEmail: '',
            ClientPhone: '',
            DownlineDomain: '',
            DownlineSubDomain: '',
            DownlineOrganizationID: '',
            selectsAppModule: {
                    AppModuleSelect: 'Ads Design',
                    AppModule: [
                        { value: 'Ads Design', label: 'Ads Design' },
                        { value: 'Campaign', label: 'Campaign' },
                        { value: 'Leads Peek', label: 'Leads Peek' },
                    ],
            },

            selectsPTAds: {
                    PTAdsSelect: 'By Fix Amount',
                    PTAds: [
                        { value: 'By Fix Amount', label: 'By Fix Amount' },
                        { value: 'By Percentage', label: 'By Percentage' },
                        
                    ],
            },

            rootDomain: '',

            mypackages: {
                freeplan: '',
                whitelabeling: {
                    monthly: '',
                    monthlyprice: '',
                    yearly: '',
                    yearlyprice: '',
                },
                nonwhitelabelling: {
                    monthly: '',
                    monthlyprice: '',
                    yearly: '',
                    yearlyprice: '',
                }
            },

            selects: {
              rootList: [],
              rootSelected: "",
              salesList: [],
              salesRepSelected: "",
              salesAESelected: "",
              salesRefSelected: "",
            },
            userData: '',

            orderBy: 'descending',
            sortOrder: {
                company_name: '',
                full_name: '',
                email: '',
                created_at: 'descending',
            },
            rootpaymentterm:[],
            rootPaymentTermsNewAgencies:[],
            selectedterms:{
            },
            twoFactorAuth: '', 
        }
    },

    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        },
        filteredPaymentTerms() {
            return this.rootpaymentterm.filter(term => {
                return this.rootpaymenttermnewagencies.some(agencyTerm => 
                    agencyTerm.term == term.value && agencyTerm.status
                );
            });
        },
        initializeSelectedTerms() {
            const initialSelectedTerms = {};
            const paymentTerm = this.$global.isRootPaymentTermsNewAgencies ? this.$global.rootpaymenttermnewagencies : this.rootpaymentterm

            paymentTerm.forEach(term => { 
                if (term.value) {
                    initialSelectedTerms[term.value] = this.$global.isRootPaymentTermsNewAgencies ? term.status : true;
                }
            });

            return initialSelectedTerms;
        }
    },

    methods: {
        removeSales(type) {
            if (type == "salesrep") {
                this.selects.salesRepSelected = "";
            }else if (type == "salesae") {
                this.selects.salesAESelected = "";
            }else if (type == "salesref") {
                this.selects.salesRefSelected = "";
            }
        },
        getSalesList() {
            this.$store.dispatch('getSalesList',{
                companyID: this.$global.idsys,
            }).then(response => {
                if (this.selects.salesList.length == 0) {
                    this.selects.salesList = response.params
                }
            },error => {
                
            });
        },
        searchKeyWord() {
            if (this.userData.user_type == 'sales') {   
                this.GetSalesDownlineList();
            }else{
                this.GetDownlineList();
            }
        },
        changePage(event){
            if (this.userData.user_type == 'sales') {   
                this.GetSalesDownlineList();
            }else{
                const sortOrderName = Object.keys(this.sortOrder).filter((key) => this.sortOrder[key])
                this.GetDownlineList(sortOrderName[0], this.orderBy);
            }
        },
        onPhoneUpdate(payload){
           this.clientPhoneNumber.countryCode = payload.countryCode
           this.clientPhoneNumber.countryCallingCode = payload.countryCallingCode
        },
        rowClicked(row) {
            this.tableData.forEach(row => {
                this.$refs.tableData.toggleRowExpansion(row,false);
            });
            this.clientPhoneNumber.number = row.phonenum
            this.clientPhoneNumber.countryCode = row.phone_country_code
            this.clientPhoneNumber.countryCallingCode = row.phone_country_calling_code
            this.$refs.tableData.toggleRowExpansion(row);
        },
        tableRowClassName({row, rowIndex}) {
                row.index = rowIndex;
                return 'clickable-rows ClientRow' + rowIndex;
        },
        ClearDownlineForm() {
            this.ClientCompanyName = '';
            this.ClientFullName = '';
            this.ClientEmail = '';
            this.ClientPhone = '';
            this.DownlineDomain = '';
            this.resetPhone()
            this.DownlineSubDomain = '';
            this.DownlineOrganizationID = '';
            this.selects.salesRepSelected = '';
            this.selects.salesAESelected = '';
            this.selects.salesRefSelected = '';
            this.ClientWhiteLabeling = true;
        },
        AddEditDownline(id) {
            $('.phonefield input').usPhoneFormat({
                format: 'xxx-xxx-xxxx',
            });

            $('#showAddEditDownline' + id).collapse('show');
        },
        resetPhone(){
            this.clientPhoneNumber={
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            }
        },
        CancelAddEditDownline(id) {
            if(id == '') {
              this.ClearDownlineForm();
              $('#showAddEditDownline' + id).collapse('hide');
            }else{
                this.$refs.tableData.toggleRowExpansion(id);
            }
            
        },
        setRootPaymentTerm() {
            this.rootpaymentterm.forEach(term => { 
                if (term.value) {
                    this.selectedterms[term.value] = true;
                }
            });
        },
        selectTfa(type) {
        this.twoFactorAuth = this.twoFactorAuth === type ? '' : type;
        console.log(this.twoFactorAuth)
         },
        ProcessAddEditDownline(id,_companyID) {
            this.$refs.frmAddDownline.validate().then(res => {
                if (!res) {
                    return false;
                }else{
                    const isValidSelectedTerms = Object.values(this.selectedterms).some(value => value === true);
                    if(!isValidSelectedTerms){
                        this.$notify({
                        type: 'danger',
                        message: 'Please select payment term',
                        });
                        return false
                    }
                   /** PROCESS ADD OR EDIT */
                   if(this.ClientFullName != '' && this.ClientEmail != '' && id == '') {
                        /** PROCESS ADD / EDIT ORGANIZATION */
                        if(id == '') {
                            $('#btnSave' + id).attr('disabled',true);
                            $('#btnSave' + id).html('Processing...');

                            if(_companyID == '') {
                                _companyID = this.companyID;
                            }

                            this.rootpaymentterm.forEach(term => {
                                if (!(term.value in this.selectedterms)) {
                                    this.$set(this.selectedterms, term.value, false);
                                }
                            });
                            let selectedTermsArray = [];
                            for (let key in this.selectedterms) {
                                selectedTermsArray.push({
                                    term: key,
                                    status: this.selectedterms[key]
                                });
                            }
                            /** CREATE CLIENT */
                            this.$store.dispatch('CreateClient', {
                                companyID: _companyID,
                                idsys: this.$global.idsys,
                                userType:'userdownline',
                                ClientCompanyName: this.ClientCompanyName,
                                ClientFullName: this.ClientFullName,
                                ClientEmail: this.ClientEmail,
                                ClientPhone: this.clientPhoneNumber.number,
                                ClientPhoneCountryCode: this.clientPhoneNumber.countryCode,
                                ClientPhoneCountryCallingCode: this.clientPhoneNumber.countryCallingCode,
                                ClientWhiteLabeling: this.ClientWhiteLabeling ? 'T' : 'F',
                                DownlineDomain: this.DownlineDomain,
                                DownlineSubDomain: this.DownlineSubDomain,
                                DownlineOrganizationID: this.DownlineOrganizationID,
                                salesRep: this.selects.salesRepSelected,
                                salesAE: this.selects.salesAESelected,
                                salesRef: this.selects.salesRefSelected,
                                selectedterms: selectedTermsArray,
                                twoFactorAuth: 'email'
                            }).then(response => {
                                //console.log(response[0]);
                                if (response.result == 'success') {
                                    this.GetDownlineList('created_at', 'descending')
                                    this.initialSearchFuse();
                                    $('#showAddEditDownline' + id).collapse('hide');
                                    $('#btnSave' + id).attr('disabled',false);
                                    $('#btnSave' + id).html('Save'); 
                                    this.ClearDownlineForm();
                                    this.$refs.frmAddDownline.reset();
                                    this.setRootPaymentTerm();
                                    this.$notify({
                                        type: 'success',
                                        message: 'Data has been added successfully',
                                        icon: 'far fa-save'
                                    });  
                                }else{
                                    $('#btnSave' + id).attr('disabled',false);
                                    $('#btnSave' + id).html('Save'); 

                                    this.$notify({
                                        type: 'primary',
                                        message: response.message,
                                        icon: 'fas fa-bug'
                                    }); 
                                }
                            },error => {
                                $('#btnSave' + id).attr('disabled',false);
                                $('#btnSave' + id).html('Save'); 

                                this.$notify({
                                    type: 'primary',
                                    message: 'Server might be busy please try again later',
                                    icon: 'fas fa-bug'
                                }); 
                            });
                            /** CREATE CLIENT */
                        }
                        /** PROCESS ADD / EDIT ORGANIZATION */
                    }else{
                        if ((id.name != '' && id.email != '') && (typeof id.name != 'undefined' && typeof id.email != 'undefined')) {
                            $('#btnSave' + id.id).attr('disabled',true);
                            $('#btnSave' + id.id).html('Processing...'); 
                            /** UPDATE CLIENT */
                            this.$store.dispatch('UpdateClient', {
                                companyID: id.company_id,
                                idsys: this.$global.idsys,
                                ClientID: id.id,
                                ClientCompanyName: id.company_name,
                                ClientFullName: id.name,
                                ClientEmail: id.email,
                                ClientPhone: this.clientPhoneNumber.number,
                                ClientPhoneCountryCode: this.clientPhoneNumber.countryCode,
                                ClientPhoneCountryCallingCode: this.clientPhoneNumber.countryCallingCode,
                                ClientWhiteLabeling: this.ClientWhiteLabeling ? 'T' : 'F',
                                DownlineDomain: id.domain,
                                DownlineSubDomain: id.subdomain,
                                DownlineOrganizationID: id.simplifi_organizationid,
                                action: 'downline',
                            }).then(response => {
                                //console.log(response[0]);
                                this.$refs.tableData.toggleRowExpansion(id);
                                $('#btnSave' + id.id).attr('disabled',false);
                                $('#btnSave' + id.id).html('Save');
                                this.getRootList();
                                this.GetDownlineList();
                                this.$notify({
                                    type: 'success',
                                    message: 'Data has been updated successfully',
                                    icon: 'far fa-save'
                                });  
                            },error => {
                                $('#btnSave' + id.id).attr('disabled',false);
                                $('#btnSave' + id.id).html('Save'); 

                                this.$notify({
                                    type: 'primary',
                                    message: 'Server might be busy please try again later',
                                    icon: 'fas fa-bug'
                                }); 
                            });
                            /** UPDATE CLIENT */
                        }
                    }
                   /** PROCESS ADD OR EDIT */
                }
            }); 
        },
        viewRootCompany() {
          this.getAgencyPlanPrice(this.userData);
        },
        getRootList() {
          this.$store.dispatch('getRootList').then(response => {
            if (this.selects.rootList.length == 0) {
              this.selects.rootList = response.params
              this.selects.rootSelected = this.$global.idsys;
              this.getAgencyPlanPrice(this.userData);
            }
          },error => {
              
          });
        },
        GetSalesDownlineList() {
            var _searchkey = '';

            if (this.searchQuery != '') {
                _searchkey = this.searchQuery;
                this.pagination.currentPage = 1;
            }

            /** GET CLIENT LIST */
            $('.el-table__empty-text').html('Loading data...');
            this.$store.dispatch('GetSalesDownlineList', {
                companyID: this.selects.rootSelected,
                usrID: this.salesID,
                idsys: this.selects.rootSelected,
                page:this.pagination.currentPage,
                searchkey: _searchkey,
            }).then(response => {
                var finalsales = "";
                
                this.pagination.currentPage = response.current_page
                this.pagination.total = response.total
                this.pagination.lastPage = response.last_page
                this.pagination.from = response.from ? response.from : 0
                this.pagination.to = response.to ? response.to : 2
                
                if (response.data.length == 0) {
                    $('.el-table__empty-text').html('No Data');
                }
                
                for(var i=0;i<response.data.length;i++) {
                    var salesreps = response.data[i].salesrep;
                    var accountexecutive = response.data[i].accountexecutive;
                    var accountref = response.data[i].accountref;

                    finalsales = "";

                    if (salesreps != "") {
                        finalsales = finalsales + '<i class="fas fa-user pr-2"></i>' + salesreps + '<br/>';
                    }

                    if (accountexecutive != "") {
                        finalsales = finalsales + '<i class="fas fa-user-headset pr-2"></i>' +accountexecutive + '<br/>';
                    }

                    if (accountref != "") {
                        finalsales = finalsales + '<i class="fas fa-user-tag pr-2"></i>' +accountref + '<br/>';
                    }

                    if (finalsales != "") {
                        response.data[i].sales = '<div style="line-height:30px;padding-top:10px;padding-bottom:10px">' + finalsales + '</div>';
                    }
                    /*var tmp = response[i].sales;
                    if (tmp != "") {
                        var tmp = tmp.split("|");

                        for(var k=0;k<tmp.length;k++) {
                            var tmp2 = tmp[k].split("-");
                            if (tmp2[1] == "Sales Representative") {
                                finalsales = finalsales + '<i class="fas fa-user pr-2"></i>' + tmp2[0] + '<br/>';
                            }
                            if (tmp2[1] == "Account Executive") {
                                finalsales = finalsales + '<i class="fas fa-user-headset pr-2"></i>' + tmp2[0];
                            }
                        }

                        response[i].sales = '<div style="line-height:30px;padding-top:10px;padding-bottom:10px">' + finalsales + '</div>';
                    }*/
                }
               
                this.tableData = response.data;
                /*$(".sortable").sortable({
		            handle : '.draggable',
                });*/

                this.initialSearchFuse();
                
                $('.sortable').nestedSortable({
                    //connectWith: ".sitemap-list",
                    forcePlaceholderSize: true,
                    handle: '.draggable',
                    items: 'li',
                    helper:	'clone',
                    opacity: .6,
                    forcePlaceholderSize: true,
                    placeholder: 'placeholder',
                    tolerance: 'pointer',
                    toleranceElement: '> div',
                    maxLevels: 10,
                    tabSize: 25,
                    isTree: true,
                    revert: 250,
                    update : function (event,ui) {	
                        var apiUrl = process.env.VUE_APP_DATASERVER_URL + '/api';
                        var serialized = $('ol.sortable').nestedSortable('serialize');
                        
                        var param = [];
		                param.push({"name":"ParentCompanyID","value":"1"});
                        param.push({"name":"dataSort","value":serialized});
                        //serialized.nestedSortable('serialize');

                        $.post(apiUrl + "/configuration/user/sort", param,
                            function(data) {			
                                //console.log(data);
                            });
                        
                    },	
                });

            },error => {
                
            });
            /** GET CLIENT LIST */
        },
        GetDownlineList(sortby,order) {
            var _sortby = '';
            var _order = '';
            var _searchkey = '';

            if (typeof(sortby) != 'undefined') {
                _sortby = sortby;
            }
            if (typeof(order) != 'undefined') {
                _order = order;
            }

            if (this.searchQuery != '') {
                _searchkey = this.searchQuery;
                this.pagination.currentPage = 1;
            }

            /** GET CLIENT LIST */
            this.tableData = [];
            $('.el-table__empty-text').html('<i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...');
            this.$store.dispatch('GetClientList', {
                companyID: this.selects.rootSelected,
                idsys: this.selects.rootSelected,
                userType:'userdownline',
                page:this.pagination.currentPage,
                searchkey: _searchkey,
                sortby: _sortby,
                order: _order,
            }).then(response => {
               this.pagination.currentPage = response.current_page
               this.pagination.total = response.total
               this.pagination.lastPage = response.last_page
               this.pagination.from = response.from ? response.from : 0
               this.pagination.to = response.to ? response.to : 2
                var finalsales = "";

                if (response.data.length == 0) {
                    $('.el-table__empty-text').html('No Data');
                }

                for(var i=0;i<response.data.length;i++) {
                    var salesreps = response.data[i].salesrep;
                    var accountexecutive = response.data[i].accountexecutive;
                    var accountref = response.data[i].accountref;

                    finalsales = "";

                    if (salesreps != "") {
                        finalsales = finalsales + '<i class="fas fa-user pr-2"></i>' + salesreps + '<br/>';
                    }

                    if (accountexecutive != "") {
                        finalsales = finalsales + '<i class="fas fa-user-headset pr-2"></i>' +accountexecutive + '<br/>';
                    }

                    if (accountref != "") {
                        finalsales = finalsales + '<i class="fas fa-user-tag pr-2"></i>' +accountref + '<br/>';
                    }

                    if (finalsales != "") {
                        response.data[i].sales = '<div style="line-height:30px;padding-top:10px;padding-bottom:10px">' + finalsales + '</div>';
                    }
                    /*var tmp = response[i].sales;
                    if (tmp != "") {
                        var tmp = tmp.split("|");

                        for(var k=0;k<tmp.length;k++) {
                            var tmp2 = tmp[k].split("-");
                            if (tmp2[1] == "Sales Representative") {
                                finalsales = finalsales + '<i class="fas fa-user pr-2"></i>' + tmp2[0] + '<br/>';
                            }
                            if (tmp2[1] == "Account Executive") {
                                finalsales = finalsales + '<i class="fas fa-user-headset pr-2"></i>' + tmp2[0];
                            }
                        }

                        response[i].sales = '<div style="line-height:30px;padding-top:10px;padding-bottom:10px">' + finalsales + '</div>';
                    }*/
                }
               
                this.tableData = response.data;
                /*$(".sortable").sortable({
		            handle : '.draggable',
                });*/

                this.initialSearchFuse();
                
                $('.sortable').nestedSortable({
                    //connectWith: ".sitemap-list",
                    forcePlaceholderSize: true,
                    handle: '.draggable',
                    items: 'li',
                    helper:	'clone',
                    opacity: .6,
                    forcePlaceholderSize: true,
                    placeholder: 'placeholder',
                    tolerance: 'pointer',
                    toleranceElement: '> div',
                    maxLevels: 10,
                    tabSize: 25,
                    isTree: true,
                    revert: 250,
                    update : function (event,ui) {	
                        var apiUrl = process.env.VUE_APP_DATASERVER_URL + '/api';
                        var serialized = $('ol.sortable').nestedSortable('serialize');
                        
                        var param = [];
		                param.push({"name":"ParentCompanyID","value":"1"});
                        param.push({"name":"dataSort","value":serialized});
                        //serialized.nestedSortable('serialize');

                        $.post(apiUrl + "/configuration/user/sort", param,
                            function(data) {			
                                //console.log(data);
                            });
                        
                    },	
                });

            },error => {
                
            });
            /** GET CLIENT LIST */
        },

        initialSearchFuse() {
            // Fuse search initialization.
            // this.fuseSearch = new Fuse(this.tableData, {
            //     keys: ['company_name','name','email','salesrep','accountexecutive','children.company_name','created_at'],
            //     threshold: 0.1
            // });
        },

        handleLike(index, row) {
            swal.fire({
                title: `You liked ${row.name}`,
                buttonsStyling: false,
                icon: 'success',
                customClass: {
                confirmButton: 'btn btn-success btn-fill'
                }
            });
        },
        handlePriceSet(index, row) {
            this.modals.pricesetup = true;
        },
        handleDelete(index, row) {
            //console.log('Row: ' + index);
            swal.fire({
                title: 'Are you sure want to delete this?',
                text: `You won't be able to revert this!`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                    /** REMOVE ORGANIZATION */
                    
                        this.$store.dispatch('RemoveDownline', {
                            companyID: row.company_id,
                            userID: row.id,
                        }).then(response => {
                            //console.log(response)
                            this.deleteRow(row);
                            swal.fire({
                                title: 'Deleted!',
                                text: `You deleted ${row.name}`,
                                icon: 'success',
                                confirmButtonClass: 'btn btn-default btn-fill',
                                buttonsStyling: false
                            });
                        },error => {
                            
                        });
                        
                    /** REMOVE ORGANIZATION */    
                }
        });
        },
        deleteRow(row) {
            let indexToDelete = this.tableData.findIndex(
                tableRow => tableRow.id === row.id
            );
            if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
            }
        },

        getAgencyPlanPrice(userData) {
            //var _idsys = this.$global.idsys;
            var _idsys = this.selects.rootSelected;
            if (_idsys == "") {
                _idsys = userData.idsys;
            }
            this.$store.dispatch('getGeneralSetting', {
                companyID: _idsys,
                settingname: 'agencyplan',
            }).then(response => {
               
                if (response.data != '') {

                    if (process.env.VUE_APP_DEVMODE == 'true') {
                        this.mypackages.nonwhitelabelling.monthly = response.data.testmode.nonwhitelabelling.monthly;
                        this.mypackages.nonwhitelabelling.monthlyprice = response.data.testmode.nonwhitelabelling.monthlyprice;
                        this.mypackages.nonwhitelabelling.yearly = response.data.testmode.nonwhitelabelling.yearly;
                        this.mypackages.nonwhitelabelling.yearlyprice = response.data.testmode.nonwhitelabelling.yearlyprice;
                        this.mypackages.whitelabeling.monthly = response.data.testmode.whitelabeling.monthly;
                        this.mypackages.whitelabeling.monthlyprice = response.data.testmode.whitelabeling.monthlyprice;
                        this.mypackages.whitelabeling.yearly = response.data.testmode.whitelabeling.yearly;
                        this.mypackages.whitelabeling.yearlyprice = response.data.testmode.whitelabeling.yearlyprice;
                        this.mypackages.freeplan = response.data.testmode.free;
                    }else{
                        this.mypackages.nonwhitelabelling.monthly = response.data.livemode.nonwhitelabelling.monthly;
                        this.mypackages.nonwhitelabelling.monthlyprice = response.data.livemode.nonwhitelabelling.monthlyprice;
                        this.mypackages.nonwhitelabelling.yearly = response.data.livemode.nonwhitelabelling.yearly;
                        this.mypackages.nonwhitelabelling.yearlyprice = response.data.livemode.nonwhitelabelling.yearlyprice;
                        this.mypackages.whitelabeling.monthly = response.data.livemode.whitelabeling.monthly;
                        this.mypackages.whitelabeling.monthlyprice = response.data.livemode.whitelabeling.monthlyprice;
                        this.mypackages.whitelabeling.yearly = response.data.livemode.whitelabeling.yearly;
                        this.mypackages.whitelabeling.yearlyprice = response.data.livemode.whitelabeling.yearlyprice;
                        this.mypackages.freeplan = response.data.livemode.free;
                    }

                    this.tableData = [];

                        if (userData.user_type == 'sales') {
                            this.salesID = userData.id;
                            this.GetSalesDownlineList();
                        }else{
                            this.GetDownlineList('created_at', 'descending');
                        }
                    
                    this.selectedterms[response.dpay] = true;
                }
            },error => {
                    
            });
        },
        updateOrderBy(newValue) {
            this.orderBy = newValue;
        }
    },

    mounted() { 
        this.userData = this.$store.getters.userData;
        this.companyID = this.userData.company_id;
        this.rootDomain = this.userData.domain;
        this.rootpaymentterm = this.$global.rootpaymentterm;
        this.rootpaymenttermnewagencies = this.$global.rootpaymenttermnewagencies;
        this.selectedterms = this.initializeSelectedTerms;

        this.getSalesList();
        //this.getAgencyPlanPrice(this.userData);
        this.getRootList();
    },

    watch: {
        rootpaymentterm: {
            handler() {
                this.selectedterms = this.initializeSelectedTerms;
            },
            immediate: true
        }
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        // searchQuery(value) {
        //     let result = this.tableData;
        //     var temp = Array();
        //     if (value !== '') {
        //         result = this.fuseSearch.search(this.searchQuery);
        //         for(let i=0;i<result.length;i++) {
        //             temp.push(result[i].item);
        //             //console.log(result[i].item);
        //         }
        //         if (result.length != 0) {
        //             this.searchedData = temp;
        //         }else{
                    
        //             if (this.tableData.length > 0) {
        //                 this.tableDataOri = [];
        //                 this.tableDataOri = this.tableData;
        //             }
        //             this.tableData = [];
        //             this.searchedData = "";
                    
        //         }
        //     }else{
        //         if (this.tableData.length == 0) {
        //             this.tableData = this.tableDataOri;
        //         }
        //         this.searchedData = result;
        //     }
            
        // }
    },
    
}
</script>
<style>
.clickable-rows td {
    cursor: pointer;
}
/* .clickable-rows .el-table, .el-table__expanded-cell {
    background-color:#1e1e2f;
} */
.clickable-rows tr .el-table__expanded-cell {
    cursor: default;
}

.container__input__agency__list {
    padding-inline: 16px;
}

.input__agency__list {
    width: 22vw;
}

.container__filter__agency__list {
    padding-inline: 16px;
    margin-top: -2px;
}

.button_add__client__agency__list {
    margin-inline: 16px;
}

@media (max-width: 575.98px) { 
    .container__filter__agency__list {
        width: 100%;
    }
    .container__input__agency__list {
        width: 100%;
    }
    .input__agency__list {
        width: 100%;
        margin-bottom: 4px;
    }
    .button__filter__agency__list {
        width: 100%;
    }
    .button_add__client__agency__list {
        margin-inline: 0px;
    }
}
</style>